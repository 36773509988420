import React from 'react';

import { AvatarSize, UserAvatarProps } from './types';

const avatarSizeClassNames: Record<AvatarSize, string> = {
  small: 'w-6 h-6',
  medium: 'w-9 h-9',
  large: 'w-14 h-14',
};

const Avatar: React.FC<UserAvatarProps> = ({
  user,
  className,
  size = 'medium',
}) => {
  const src = user.photo ?? '/images/placeholders/avatar/default.svg';
  const alt = user.name ? `${user.name}'s Avatar` : `User avatar image`;

  return (
    <img
      src={src}
      alt={alt}
      className={`${avatarSizeClassNames[size]} rounded-full ${className} border border-indigo-900`}
      referrerPolicy="no-referrer"
    />
  );
};

export default Avatar;
