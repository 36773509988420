interface Organization {
  id: number;
  name: string;
  slug: string;
}

interface User {
  id: number;
  email: string;
  name?: string;
  photo?: string;
  title?: string;
  status: string;
  organization?: Organization;
  integrations: {
    zapier?: {
      api_key: string;
    };
    slack?: {
      user_id: string;
    };
  };
  role?: {
    id: number;
    display: string;
    name: string;
    is_admin: boolean;
  };
}

interface Topic {
  id: number;
  name: string;
  description?: string;
  color: string;
  icon: string;
  created_at: string;
  is_archived: boolean;
  is_following: boolean;
  votes_to_move: number;
  ideas_count: number;
  ideas: Array<Idea>;
  owner?: {
    id: number;
    name: string;
    photo: string;
  };
}

enum IdeaState {
  Submitted = 'Submitted',
  Evaluating = 'Evaluating',
  Executing = 'Executing',
  Completed = 'Completed',
  Rejected = 'Rejected',
}

interface IdeaSummary {
  id: number;
  title: string;
  description?: string;
  pitch: string;
  user: Pick<User, 'id' | 'name' | 'photo'>;
  created_at: string;
  updated_at: string;
  is_upvoter: boolean;
  state: IdeaState;
  tags: string[];
  upvoters_count: number;
  topic: Pick<
    Topic,
    'id' | 'name' | 'description' | 'color' | 'icon' | 'created_at'
  >;
}

interface UserAward {
  id: number;
  name: string;
  description: string;
  icon: string;
  completed_at: string;
}

interface Idea extends IdeaSummary {
  tags: string[];
  comments_count: number;
  followers_count: number;
  is_following: boolean;
}

interface IdeaComment {
  id: number;
  content: string;
  parent_id: number | null;
  user: Pick<User, 'id' | 'name' | 'photo' | 'email'>;
  created_at: string;
  updated_at: string;
  replies: IdeaComment[];
}

interface PresignedPost {
  expires_at: string;
  public_url: string;
  upload: {
    url: string;
    fields: Record<string, string>[];
  };
}

export { IdeaState };
export type {
  User,
  Topic,
  IdeaSummary,
  Idea,
  IdeaComment,
  PresignedPost,
  Organization,
  UserAward,
};
