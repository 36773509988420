import Link from 'next/link';
import React from 'react';

const Hero: React.FC = () => (
  <div className="bg-white max-w-7xl mx-auto overflow-hidden py-12 lg:flex items-center px-4">
    <div className="w-full lg:w-1/2">
      <div className="pb-8 md:pb-12 lg:w-full lg:pb-16 xl:pb-24">
        <main className="mt-10 mx-auto">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <p className="block l:inline">Your organization's</p>
              <p className="block text-indigo-600 l:inline">
                virtual suggestion box
              </p>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Leverage the best minds in your organization. Gain new insights
              and different perspectives to grow from the inside out.
            </p>
            <div className="space-y-2 lg:space-y-0 mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start md:space-x-2">
              <div className="rounded-md shadow">
                <Link href="/register?ref=cta">
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    Start here
                  </a>
                </Link>
              </div>
              <div className="rounded-md shadow">
                <a
                  href="mailto:hello@lampant.com"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                >
                  Book a demo
                </a>
              </div>
            </div>
            <div className="block md:hidden text-sm text-center">
              Have an account?{' '}
              <Link href="/login">
                <a className="underline text-gray-700 hover:text-gray-500">
                  Login
                </a>
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className="w-full lg:w-1/2 lg:p-8">
      <img
        className="border shadow-xl rounded-lg"
        src="/images/home/app_screenshot.png"
        alt="Lampant App"
      />
    </div>
  </div>
);

export default Hero;
