import React from 'react';
import { IdeaState } from '../../types';
import { IdeaCardStateProps } from './types';

const stateColors: Record<IdeaState, string> = {
  [IdeaState.Submitted]: 'gray',
  [IdeaState.Evaluating]: 'yellow',
  [IdeaState.Executing]: 'blue',
  [IdeaState.Completed]: 'green',
  [IdeaState.Rejected]: 'red',
};

const IdeaCardState: React.FC<IdeaCardStateProps> = ({ state }) => {
  if (state === IdeaState.Submitted) return null;

  const color = stateColors[state];

  return (
    <span
      className={`rounded-sm text-xs border border-${color}-300 bg-${color}-100 text-black font-thin p-1`}
    >
      {state}
    </span>
  );
};

export default IdeaCardState;
